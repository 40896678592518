exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-article-mtls-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/pio/Projects/repo/projects/gawsoftpl/gawsoft/frontend/src/content/blog/article-mtls.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-article-mtls-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-kind-load-balancer-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/pio/Projects/repo/projects/gawsoftpl/gawsoft/frontend/src/content/blog/kind-load-balancer.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-kind-load-balancer-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-mtls-intermediate-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/pio/Projects/repo/projects/gawsoftpl/gawsoft/frontend/src/content/blog/mtls-intermediate.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-mtls-intermediate-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-thanos-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/pio/Projects/repo/projects/gawsoftpl/gawsoft/frontend/src/content/blog/thanos.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-thanos-mdx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-article-mtls-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/pio/Projects/repo/projects/gawsoftpl/gawsoft/frontend/src/content/blog/article-mtls.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-article-mtls-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-kind-load-balancer-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/pio/Projects/repo/projects/gawsoftpl/gawsoft/frontend/src/content/blog/kind-load-balancer.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-kind-load-balancer-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-mtls-intermediate-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/pio/Projects/repo/projects/gawsoftpl/gawsoft/frontend/src/content/blog/mtls-intermediate.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-mtls-intermediate-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-thanos-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/home/pio/Projects/repo/projects/gawsoftpl/gawsoft/frontend/src/content/blog/thanos.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-content-blog-thanos-mdx" */)
}

